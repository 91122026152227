import "./Popup.scss";

const PopupContent = () => {
  return (
    <div className="Popup">
      <div className="PopupContainer">
        <div className="PopupLinks">
          <h1>КОнтакты</h1>
          <span className="textPopup">
            Если у вас есть вопросы или нужна консультация, свяжитесь с нами!
            Оставьте заявку и мы перезвоним Вам в течение 30 минут
          </span>
          <div className="Links">
            <div>
              <span className="viber">
                <a
                  href={
                    window.location.href.includes("/customers")
                      ? "viber://chat?number=%2B375339906077"
                      : "viber://chat?number=%2B375339904077"
                  }
                >
                  Viber
                </a>
              </span>
              <span className="tg">
                <a
                  href={
                    window.location.href.includes("/customers")
                      ? "https://t.me/gruzcoby"
                      : "https://t.me/gruzco"
                  }
                >
                  Telegram
                </a>
              </span>
              <span className="whatsapp">
                <a
                  href={
                    window.location.href.includes("/customers")
                      ? "https://wa.me/375339906077"
                      : "https://wa.me/375339904077"
                  }
                >
                  Whatsapp
                </a>
              </span>
            </div>
            <div>
              <span>
                <a
                  className="tel-href"
                  href={
                    window.location.href.includes("/customers")
                      ? "tel:+375 (33) 990-60-77"
                      : "tel:+375 (29) 346-90-36"
                  }
                >
                  {window.location.href.includes("/customers")
                    ? "+375 (33) 990-60-77"
                    : "+375 (29) 346-90-36"}
                </a>
              </span>
              <span className="hoverMail">gruzco@mail.ru</span>
            </div>
            <div className="mainButton123">
              <a
                className="href-popup"
                href={
                  window.location.href.includes("/customers")
                    ? "http://forma.gruzco.by/"
                    : "http://form.gruzco.by/"
                }
              >
                <span>Оставить заявку</span>
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupContent;
