import React, { useState } from "react";
import "./App.css";
import telegram from "../main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRoute from "./route";
import Services from "./App/Services/Services";
import Footer from "./App/Footer/Footer";
 
const App = () => {
  const menuId = "main-menu";
  const [open, setOpen] = useState(false);
  telegram();
  return (
    <BrowserRouter basename="/">
      <div className="main-content">
        
         <Routes>
          <Route path="/" element={<AppRoute />} />
          <Route path="/customers" element={<AppRoute />} />
          <Route path="*" element={<Footer />} />
        </Routes>
      </div>
    
    </BrowserRouter>
  );
};

export default App;
