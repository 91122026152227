import React, { useState, useEffect } from "react";
import "./Callback.scss";
import phoneIcon from "../../../assets/../assets/phone-icon1.png";
const Callback = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  console.log(isOpen);
  return (
    <div className="callback-widget">
      <div className="circle phone" onClick={toggleMenu}>
        <div class="textInfo">Заказать звонок</div>
        <div class="iconBlock">
          <div class="iconPhone shake shake-constant">
            <i class="fa fa-phone" aria-hidden="true">
              <img src={phoneIcon} className={"iconPhone"} alt="phone" />
            </i>
          </div>
        </div>
      </div>
      <a
        className={`circle menu ${isOpen ? "open5" : "openNo"}`}
        id="phone"
        href={
          window.location.href.includes("/customers")
            ? "tel:+375 (33) 990-60-77"
            : "tel:+375 (29) 346-90-36"
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open4" : "openNo"}`}
        id="form"
        href={
          window.location.href.includes("/customers")
            ? "http://forma.gruzco.by/"
            : "http://form.gruzco.by/"
        }
      ></a>{" "}
      <a
        className={`circle menu ${isOpen ? "open" : "openNo"}`}
        id="viber"
        href={
          window.location.href.includes("/customers")
            ? "viber://chat?number=%2B375339906077"
            : "viber://chat?number=%2B375339904077"
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open1" : "openNo"}`}
        id="telegram"
        href={
          window.location.href.includes("/customers")
            ? "https://t.me/gruzcoby"
            : "https://t.me/gruzco"
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open2" : "openNo"}`}
        id="whatsup"
        href={
          window.location.href.includes("/customers")
            ? "https://wa.me/375339906077"
            : "https://wa.me/375339904077"
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open3" : "openNo"}`}
        id="gmail"
        href="mailto:gruzco@mail.ru?subject=Оформить заказ&body=Содержание"
      ></a>
    </div>
  );
};

export default Callback;
